.acuity-embed-button {
    background: #909090;
    color: #fff;
    padding: 8px 12px;
    border: 0px;
    -webkit-box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.15) inset;
    -moz-box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.15) inset;
    box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.15) inset;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
  }

.booking-box > h1 {
    text-align: left;
  }