.footer {
    justify-content: center;
    width: 100%;
    background-color: rgb(222, 222, 224, 0.45);
}

.footer-container {
    justify-content: space-evenly;
    font-size: 0.8rem;
    color: #707070c0;
}

a {
    text-decoration: none;
    color: #707070c0;
}