.carosel-container {
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.testimonial {
    flex: 1 1 0;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 260px;
    align-items: center;
    animation-name: fadeInOut;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

/* .first {
    text-align: left;
}

.middle {
    text-align: center;
}

.end {
    text-align: right;
} */


@media screen and (max-width: 67rem) {
  /* .main{
    width: auto;
  } */
  
}

@keyframes fadeInOut{
    0% {opacity: 0%;}
    50% {opacity: 100%;}
    100% {opacity: 0%;}
}