body {
  background-image: url("/public/images/background.png");
  background-size: auto;
  background-repeat: no-repeat;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #535153;
}


#root {
  max-width: 85rem;
  margin: auto;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}
