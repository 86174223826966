.main {
  width: 65rem;
  justify-content: center;
  align-content: center;

  padding: 30px;
  padding-top: 0px;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
}

.transparent-box{
  background-color: rgba(225, 229, 236, 0.453);
  padding-top: 2rem;
}


.booking-box {
  text-align: center;
  padding-top: 2rem;
  width: 50rem;
  align-self: center;
}

.text-container {
  border: 0.5rem solid #909090;
  border-style: double;
  padding: 1.7rem;
}

.quote-container {
  font-style: italic;
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 2rem;
}

.bottom-quote{
  font-size: 1.75rem;
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.decorated{
  overflow: hidden;
  text-align: center;
}
.decorated > span{
 position: relative;
 display: inline-block;
}
.decorated > span:before, .decorated > span:after{
 content: '';
 position: absolute;
 top: 50%;
 border-bottom: 2px solid;
 border-color: #909090;
 width: 591px; /* half of limiter*/
 margin: 0 20px;
}
.decorated > span:before{
 right: 100%;
}
.decorated > span:after{
 left: 100%;
}

/* .booking-button {
  position: absolute;
  top: 5rem;
  left:5rem;
} */
.top-button {
  width: 15rem;
  text-align: center;
}


ul {
  padding: 0;
  list-style-position: inside;
}
li {
  padding-bottom: 1rem;
  list-style: none;
}



@media screen and (max-width: 67rem) {
  .main{
    width: auto;
    padding: 15px;
    padding-top: 0;
  }
  
}

@media screen and (max-width:616px) {
  .quote-container {
    font-size: 1.25rem;
  }

  .top-quote{
    padding-bottom: 0rem;
  }
}
