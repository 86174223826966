.accordion{
    padding-left: 1rem;
    background-color: rgba(225, 229, 236, 0.453);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.accordion-link{
    padding-left: 1rem;
    border-color:  #909090;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    width: 8rem;
}